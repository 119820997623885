// src/theme.ts

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          height: '33px',
          'line-height': '33px',
          _disabled: {
            color: '#999999',
            'background-color': '#f2f2f2 !important',
            opacity: 1,
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          _disabled: {
            color: '#999999',
            'background-color': '#f2f2f2 !important',
            opacity: 1,
          },
          height: '33px',
          'line-height': '33px',
        },
      },
    },
    Textarea: {
      baseStyle: {
        _disabled: {
          color: '#999999',
          'background-color': '#f2f2f2 !important',
          opacity: 1,
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        'font-size': '13.2px !important',
      },
      'table tr th, table tr td': {
        'font-size': '11.2px !important',
      },
      '.chakra-badge': {
        'font-size': '10.2px !important',
      },
      'header, h2': { 'font-size': '20px !important' },
    },
  },
  fonts: {
    body: `'Roboto Condensed', sans-serif`,
    heading: `'Roboto Condensed', sans-serif`,
  },
});

export const colorList = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
  'gray',
];

export const colors = {
  main: colorList[5],
};

export default theme;
