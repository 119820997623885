import { useEffect, useState } from 'react';
import { entries, keyBy } from 'lodash';
import { Text, Box, Checkbox, Flex, useToast, Input } from '@chakra-ui/react';
import { axiosInstance } from '../../../api/init';
import useForm, { TField } from '../../form/Form';
import { useGlobalContext } from '../../../useGlobalContext';
import { TInput, useTabsContext } from '../../tabs/useTabsContext';
import { exportToExcel, strNomalize } from '../../../helpers';
import {
  addressInputs,
  addressInputskeys,
  typesOfInputOptions,
} from './ManageInputs';
import { addUploadMatch, fetchMatch } from '../../../api/tabsApi';
import DuplicateTable from '../../tabs/DuplicateTable';

const useManageImport = () => {
  const toast = useToast();

  const { dataInputs, getData, getInputs, getUsersList } = useTabsContext();
  const { setModalType, modalData, setModalData, onClose } = useGlobalContext();

  const [isNew, setIsNew] = useState<boolean[]>([]);
  const [currMatch, setCurrMatch] = useState({});

  const { data, file } = modalData || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [headers, firstRow] = data || [];

  const allInputs: TInput[] = dataInputs?.reduce(
    (prev, input) =>
      input.name_id === 'planning_type'
        ? prev
        : input.type !== 'address'
        ? [...prev, input]
        : [
            ...prev,
            ...addressInputskeys.map((key, i) => ({
              ...input,
              label: `${input.label} ${addressInputs[i]}`,
              name_id: `${input.name_id}_${key}`,
            })),
          ],
    [] as any,
  );

  const inputsByNameId = keyBy(allInputs, 'name_id');

  const nonExistingHeaders = headers?.filter(
    (header: string) =>
      !inputsByNameId[
        strNomalize(header.replace(/adresse|ville|code|postal/gi, '').trim())
      ],
  );

  const fields: TField[] = headers?.map((header: any) => ({
    label: '',
    name: strNomalize(header),
    type: 'text',
  }));

  const matchFields: TField[] = headers?.map((header: any, idx: number) => ({
    label: '',
    name: strNomalize(header),
    type: 'select',
    options: isNew[idx]
      ? [...typesOfInputOptions, { label: 'Fichiers', value: 'files' }]
      : allInputs?.map((input) => ({
          label: `${input.label} (${input.name_id})`,
          value: input.name_id,
        })),
    placeholder: isNew[idx] ? 'Choisir un type' : 'Choisir un champ',
  }));

  const { Form } = useForm(fields);
  const {
    Form: MatchForm,
    formData: matchFormData,
    setFormData,
  } = useForm(matchFields, undefined, undefined, true);

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    const filteredMatch = nonExistingHeaders.reduce(
      (prev: any, _a: any, idx: number) =>
        (isNew[idx] || !matchFormData[strNomalize(nonExistingHeaders[idx])]) &&
        !/address|adresse/.test(
          matchFormData[strNomalize(nonExistingHeaders[idx])],
        )
          ? prev
          : [
              ...prev,
              {
                fileKey: strNomalize(nonExistingHeaders[idx]),
                inputKey: matchFormData[strNomalize(nonExistingHeaders[idx])],
              },
            ],
      [] as any,
    );

    filteredMatch.length && (await addUploadMatch(filteredMatch));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('headType', JSON.stringify(matchFormData));

    setModalData((d: any) => ({ ...d, matchFormData }));

    try {
      toast({
        title: 'Import en cours',
        status: 'warning',
      });

      const response = await axiosInstance.post('/api/data/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await getData();
      await getUsersList(true);
      await getInputs(true);

      toast({
        title: `${response.data.message}`,
        status: 'success',
      });

      if (response.data.data.duplicate) {
        setModalData((d: any) => ({
          ...d,
          duplicate: response.data.data.duplicate,
        }));
        setModalType('displayDuplicate');
      } else {
        setModalData(null);
        onClose();
      }
    } catch (error) {
      console.error('Error uploading the file', error);
    }
  };

  useEffect(() => {
    setIsNew(() => Array(nonExistingHeaders?.length).fill(!allInputs.length));
  }, [nonExistingHeaders?.length, file]);

  useEffect(() => {
    const getMatchs = async () => {
      const respMatch = await fetchMatch();

      const filteredMatch = entries(respMatch?.data).reduce(
        (prev, [k, v]: any) => (inputsByNameId[v] ? { ...prev, [k]: v } : prev),
        {},
      );

      setCurrMatch(filteredMatch);
    };

    file && getMatchs();
  }, [file]);

  useEffect(() => {
    const allMatch = headers?.reduce((prev: any, header: string) => {
      const id = inputsByNameId[strNomalize(header)]?.name_id;

      return id ? { ...prev, [id]: id } : prev;
    }, {});

    setFormData({ ...currMatch, ...allMatch });
  }, [currMatch]);

  const renderManageImport = (
    <>
      <Text fontStyle='italic' mb={2} textAlign='center' w='50%' ml='50%'>
        Toute ligne vide sera ignoré
      </Text>
      {Form.map((F, idx) => (
        <Flex alignItems='center'>
          <Box w='45%' mr={3}>
            {F}
          </Box>
          {/* <Box w='25%' mr={3}>
            {firstRow[idx]}
          </Box> */}
          <Flex mb={3}>
            <Text>Nouveau ?</Text>
            <Checkbox
              mx={3}
              isChecked={isNew[idx]}
              onChange={(e) => {
                setFormData((fd) => ({
                  ...fd,
                  [strNomalize(headers[idx])]: null,
                }));
                setIsNew((n) => {
                  const nn = [...n];
                  nn[idx] = e.target.checked;
                  return nn;
                });
              }}
            />
          </Flex>
          <Flex flexGrow={1}>
            <Flex flexGrow={1}>{MatchForm[idx]}</Flex>
            {isNew[idx] &&
              /number|address|adresse/.test(
                matchFormData[strNomalize(headers[idx])],
              ) && (
                <Box w='50%' ml={3}>
                  {/^number/.test(matchFormData[strNomalize(headers[idx])]) && (
                    <Input
                      placeholder='Unité'
                      onChange={(e) =>
                        setFormData((fd) => ({
                          ...fd,
                          [strNomalize(headers[idx])]: `${
                            fd[strNomalize(headers[idx])].split('-')[0]
                          }-${e.target.value}`,
                        }))
                      }
                    />
                  )}
                  {/address|adresse/.test(
                    matchFormData[strNomalize(headers[idx])],
                  ) && (
                    <Input
                      ml={1}
                      placeholder='Nom'
                      onChange={(e) =>
                        setFormData((fd) => ({
                          ...fd,
                          [strNomalize(headers[idx])]: `${fd[
                            strNomalize(headers[idx])
                          ]
                            .split('_')
                            .slice(0, 2)
                            .join('_')}_${e.target.value}`,
                        }))
                      }
                    />
                  )}
                </Box>
              )}
          </Flex>
        </Flex>
      ))}
    </>
  );

  const renderDuplicate = <DuplicateTable data={modalData} />;

  return {
    computeBeforeUpload: {
      component: renderManageImport,
      action: handleUpload,
      title: 'Import correspondance',
      actionLabel: 'Importer',
    },
    displayDuplicate: {
      component: renderDuplicate,
      title: 'Doublons',
      action: () => exportToExcel(modalData.duplicate),
      actionLabel: 'Exporter',
    },
  };
};

export default useManageImport;
