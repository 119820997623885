import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { useTabsContext } from './useTabsContext';
import ModalTable from '../modal/ModalTable';
import NormalTable, { TNormalTable } from '../table/NormalTable';

const usersColumns = [
  {
    header: <>Login</>,
    accessorKey: 'login',
    meta: { _id: 'login' },
  },
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
  {
    accessorKey: 'role',
    meta: { _id: 'role' },
  },
  {
    header: <>Désactivé</>,
    accessorKey: 'blocked',
    meta: { _id: 'blocked', type: 'switch' },
  },
  {
    header: <>Sans restriction</>,
    accessorKey: 'noRestrict',
    meta: { _id: 'noRestrict', type: 'switch' },
  },
  {
    header: <>MDP Temporaire</>,
    accessorKey: 'tempPassword',
    meta: { _id: 'tempPassword' },
  },
];

const rolesColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
];

const inputsColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'label',
    meta: { _id: 'label' },
  },
  {
    accessorKey: 'ID',
    accessorFn: (row: any) => row.name_id,
    meta: { _id: 'name_id' },
  },
  {
    accessorKey: 'group',
    meta: { _id: 'group' },
  },
  {
    accessorKey: 'type',
    meta: { _id: 'type' },
  },
];

const documentColumns = [
  {
    header: <>Nom</>,
    accessorKey: 'name',
    meta: { _id: 'name' },
  },
  {
    header: <>Description</>,
    accessorKey: 'description',
    meta: { _id: 'description' },
  },
  {
    header: <>Nombre de document</>,
    accessorKey: 'numberExpected',
    meta: { _id: 'numberExpected' },
  },
];

const SettingsTabs = () => {
  const {
    dataInputs,
    userList,
    roleList,
    settingsTabs,
    tabToUpdate,
    documentList,
    getRolesList,
    getUsersList,
    setTabToUpdate,
  } = useTabsContext();

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabToUpdate(settingsTabs[0]);
  }, [settingsTabs]);

  const tables: Record<string, TNormalTable> = {
    inputs: {
      data: dataInputs || [],
      columns: inputsColumns,
      editEntryKey: 'editInput',
      noResize: true,
    },
    users: {
      data: userList.reverse() || [],
      columns: usersColumns,
      editEntryKey: 'editUser',
      onLoad: async () => {
        await getRolesList();
        getUsersList();
      },
      noResize: true,
    },
    roles: {
      data: roleList || [],
      columns: rolesColumns,
      editEntryKey: 'editRole',
      onLoad: getRolesList,
      noResize: true,
    },
    documents: {
      data: documentList || [],
      columns: documentColumns,
      editEntryKey: 'editDocument',
      onLoad: getRolesList,
      noResize: true,
    },
  };

  return (
    <>
      <Tabs
        variant='enclosed'
        isLazy
        borderColor='gray.300'
        onChange={setTabIndex}
        index={tabIndex}
      >
        <TabList flexWrap='wrap'>
          {settingsTabs.map((setting) => (
            <Tab
              key={setting._id}
              textTransform='capitalize'
              onClick={() => setTabToUpdate(setting)}
            >
              {setting.title}
            </Tab>
          ))}
        </TabList>
        {tables[tabToUpdate?._id || ''] && (
          <NormalTable {...tables[tabToUpdate?._id as any]} />
        )}
        <TabPanels></TabPanels>
      </Tabs>
      <ModalTable />
    </>
  );
};

export default SettingsTabs;
