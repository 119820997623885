import {
  Text,
  Checkbox,
  Flex,
  Tag,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionPanel,
  Icon,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { allIcons, icons } from '../utils/Icon';
import { TAction } from './Form';
import { useGetSelectFromId } from '../../helpers';
import { IconType } from 'react-icons';
import { colors } from '../../theme';

export type TLeaf = {
  name: string;
  id: any;
  type?: string;
  childs?: TLeaf[];
  icon?: IconType | string;
};
type TLeafProps = {
  leafs: TLeaf[];
  selectedValues: any;
  onChange: (
    value: string,
    childs?: TLeaf[],
    parentId?: any,
    noSpread?: boolean,
  ) => void;
  idx?: number;
  actions?: TAction[];
  parentIdProp?: any;
};

const Leaf = ({
  leafs,
  onChange,
  selectedValues,
  idx: idxProp = 0,
  actions,
  parentIdProp,
}: TLeafProps) => {
  const getSelectFromId = useGetSelectFromId();
  const [status, setStatus] = useState<Record<string, boolean>>({});

  return (
    <Accordion allowMultiple>
      {leafs.map((leaf) => {
        const { name, id, childs, type, icon } = leaf;
        const Btn = childs?.length ? AccordionButton : Flex;
        const parentId = idxProp === 0 ? id : parentIdProp;

        return (
          <AccordionItem border='none'>
            <Flex borderBottomWidth={1}>
              <Flex
                mr={3}
                flexGrow={1}
                backgroundColor={childs ? `${colors.main}.50` : ''}
                borderRadius={15}
                pl={3}
                my={1}
                _hover={{
                  backgroundColor: childs ? `${colors.main}.100` : '',
                }}
                cursor={childs ? 'pointer' : 'auto'}
              >
                <Btn
                  p={0}
                  alignItems='center'
                  _hover={{ backgroundColor: 'none' }}
                  onClick={(e: any) =>
                    setStatus((s) => ({
                      ...s,
                      [leaf.id]:
                        e.target.getAttribute('aria-expanded') !== 'true',
                    }))
                  }
                >
                  <Icon
                    boxSize={7}
                    as={
                      allIcons[`${icon}`] ||
                      icon ||
                      (childs ? icons.page : icons.column)
                    }
                  />
                  <Text p={2} textTransform='capitalize'>
                    {getSelectFromId(name)?.label || name}
                    {type === 'address' ? ' adresse' : ''}
                  </Text>
                  <Box flexGrow={1} />
                  {childs && (
                    <Icon
                      boxSize={5}
                      as={
                        allIcons[`HiChevron${status[leaf.id] ? 'Down' : 'Up'}`]
                      }
                      mr={3}
                    />
                  )}
                </Btn>
              </Flex>
              <Flex>
                {actions?.map(
                  ({ title, label, level, noSpread }) =>
                    (level === undefined || level === idxProp) && (
                      <Tag m={2} p={2}>
                        <Checkbox
                          // isIndeterminate
                          isChecked={!!selectedValues[`${id}-${title}`]}
                          onChange={() =>
                            onChange(
                              `${id}-${title}`,
                              childs,
                              parentId,
                              noSpread,
                            )
                          }
                        >
                          {label}
                        </Checkbox>
                      </Tag>
                    ),
                )}
              </Flex>
            </Flex>
            {childs && (
              <AccordionPanel pr={0} pb={0} pt={0}>
                <Leaf
                  leafs={childs}
                  selectedValues={selectedValues}
                  onChange={onChange}
                  idx={idxProp + 1}
                  actions={actions}
                  parentIdProp={parentId}
                />
              </AccordionPanel>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

type TTreeInput = {
  list: TLeaf[];
  actions: TAction[];
  selected?: any;
  onChange: (e: any) => void;
};

const TreeInput = ({ list, actions, selected = {}, onChange }: TTreeInput) => {
  const [selectedValues, setSelectedValues] =
    useState<Record<string, any>>(selected);

  const toggleTree = (
    value: string,
    childs?: TLeaf[],
    parentId?: any,
    noSpread?: boolean,
  ) => {
    const copyV = { ...selectedValues };
    const isRemove = copyV[value];
    const suffix = value.split('-')[1];

    if (isRemove) {
      copyV[`${parentId}-${suffix}`] = false;
      copyV[value] = false;

      if (!noSpread) {
        childs?.forEach(({ id }) => {
          copyV[`${id}-${suffix}`] = false;
        });
        childs?.forEach(({ childs: subChilds }) => {
          subChilds?.forEach(({ id }) => {
            copyV[`${id}-${suffix}`] = false;
          });
        });
      }
    } else {
      copyV[value] = true;

      if (!noSpread) {
        childs?.forEach(({ id }) => {
          copyV[`${id}-${suffix}`] = true;
        });
        childs?.forEach(({ childs: subChilds }) => {
          subChilds?.forEach(({ id }) => (copyV[`${id}-${suffix}`] = true));
        });
      }
    }

    setSelectedValues(copyV);
  };

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <Leaf
      leafs={list}
      selectedValues={selectedValues}
      onChange={toggleTree}
      actions={actions}
    />
  );
};

export default TreeInput;
