import { Text } from '@chakra-ui/react';
import { addData, deleteData, updateData } from '../../../api/tabsApi';
import useForm from '../../form/Form';
import { useGlobalContext } from '../../../useGlobalContext';
import { TColumnData, useTabsContext } from '../../tabs/useTabsContext';
import SubTabs from '../../tabs/SubTabs';

const useManageEntries = () => {
  const { getData, subTabs, rowData, currentTab, canI } = useTabsContext();
  const { fetchAppointments, customMenuSelected, modalType, onClose } =
    useGlobalContext();

  const inputs = subTabs.reduce(
    (prev, tab) => [
      ...prev,
      ...tab.tableColumns.map((col) => ({
        ...col,
        parent_id: tab._id,
        type: col.type === 'text' ? '' : col.type,
      })),
    ],
    [] as TColumnData[],
  );

  const isAdd = modalType === 'addEntry';
  const isPlanning = customMenuSelected === 'planning';

  const { Form, handleSubmit, pending } = useForm(
    inputs,
    isAdd ? addData : updateData,
    modalType !== 'addEntry' && currentTab?.planable,
  );

  const handleEntry = async () => {
    const resp = await handleSubmit();

    if (resp.isDuplicate) {
      return;
    }

    await getData();

    if (!isAdd && isPlanning) {
      fetchAppointments();
    }

    onClose();
  };

  const handleDeleteEntry = async () => {
    await deleteData(rowData._id);
    await getData();
    onClose();
  };

  const renderManageEntries = <SubTabs forms={Form} />;

  const updatePermission = canI(
    currentTab?._id || customMenuSelected,
    'update',
  );
  const deletePermission = currentTab?._id && canI(currentTab?._id, 'delete');

  const renderDeleteEntry = (
    <Text>Confirm you want to delete this entry ?</Text>
  );

  return {
    addEntry: {
      component: renderManageEntries,
      action: handleEntry,
      title: 'Add entry',
      actionLabel: 'Add',
    },
    editEntry: {
      component: renderManageEntries,
      canDelete: deletePermission ? 'deleteEntry' : '',
      action: updatePermission && handleEntry,
      pending,
      title: 'Edit entry',
      actionLabel: 'Edit',
    },
    deleteEntry: {
      component: renderDeleteEntry,
      action: handleDeleteEntry,
      title: 'Delete entry',
      actionLabel: 'Confirm',
    },
  };
};

export default useManageEntries;
