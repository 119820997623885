import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Icon,
  Flex,
  Text,
  Switch,
  MenuGroup,
  Divider,
  Input,
  InputGroup,
  InputRightAddon,
  Heading,
} from '@chakra-ui/react';
import { useGlobalContext } from '../../useGlobalContext';
import { allIcons } from '../utils/Icon';
import { useTabsContext } from '../tabs/useTabsContext';
import ActionButtons from '../tabs/ActionButtons';
import UploadComponent from '../import/UploadComponent';
import { colors } from '../../theme';

const Toolbar = () => {
  const {
    tabs,
    currentTab,
    tabToUpdate,
    setCurrentTab,
    setGlobalFilter,
    canI,
  } = useTabsContext();
  const {
    login,
    isAdmin,
    isSuper,
    isEditMode,
    filterActive,
    showNbLine,
    customMenuSelected,
    setUser,
    setModalType,
    setShowNbLine,
    toggleFilter,
    onNavOpen,
    onToggle,
    setToken,
    toggleEditMode,
    setCustomMenuSelected,
  } = useGlobalContext();

  const handleMode = () => {
    if (isEditMode) {
      onToggle();
    } else {
      onNavOpen();
    }

    toggleEditMode();

    if (!currentTab?._id && !/planning|duplicate/.test(customMenuSelected)) {
      setCurrentTab(tabs[0]);
      setCustomMenuSelected('');
    }
  };

  return (
    <Flex
      p={2}
      backgroundColor={`${colors.main}.200`}
      boxShadow='xl'
      alignItems='center'
      zIndex={2}
    >
      <Heading
        ml={3}
        textTransform='capitalize'
        fontSize={26}
        fontWeight='bold'
        fontFamily="'Kanit', sans-serif"
      >
        {tabToUpdate?.title ||
          (customMenuSelected === 'planning' ? 'Planning' : '')}
      </Heading>
      <Box flexGrow={1} />
      <InputGroup w={300} mr={3}>
        <Input
          backgroundColor='white'
          placeholder='Rechercher'
          onChange={(e) => setGlobalFilter(e.target?.value || undefined)}
        />
        <InputRightAddon>
          <Icon as={allIcons.HiMagnifyingGlass} />
        </InputRightAddon>
      </InputGroup>
      {tabToUpdate?._id && canI(tabToUpdate?._id, 'update') && (
        <Button onClick={() => setModalType('runAction')} mr={3}>
          Actions
        </Button>
      )}
      {isAdmin ? <UploadComponent /> : null}
      <ActionButtons />
      <Menu>
        <MenuButton
          as={Button}
          backgroundColor={`${colors.main}.400`}
          color='white'
          _hover={{ backgroundColor: `${colors.main}.300` }}
          _active={{ backgroundColor: `${colors.main}.300` }}
        >
          <Flex alignItems='center'>
            <Icon as={allIcons.HiUserCircle} mr={2} />
            {login}
          </Flex>
        </MenuButton>
        <MenuList zIndex={2}>
          <MenuGroup title='Options'>
            <MenuItem>
              <Text flexGrow={1} onClick={toggleFilter}>
                Avec filtres
              </Text>
              <Switch isChecked={filterActive} onChange={toggleFilter} />
            </MenuItem>
            <MenuItem>
              <Text flexGrow={1} onClick={() => setShowNbLine((b) => !b)}>
                Avec numero de ligne
              </Text>
              <Switch
                isChecked={showNbLine}
                onChange={() => setShowNbLine((b) => !b)}
              />
            </MenuItem>
            {isAdmin && !isSuper && (
              <MenuItem>
                <Text flexGrow={1} onClick={handleMode}>
                  Mode édition
                </Text>
                <Switch isChecked={isEditMode} onChange={handleMode} />
              </MenuItem>
            )}
          </MenuGroup>
          <Divider my={3} />
          <MenuItem
            onClick={() => setUser((u) => ({ ...u, tempPasswordForced: true }))}
          >
            Changer mon mot de passe
          </MenuItem>
          <Divider my={3} />
          <MenuItem onClick={() => setToken('')}>Déconnexion</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Toolbar;
