import { Button } from '@chakra-ui/react';
import { useGlobalContext } from '../../useGlobalContext';
import { useTabsContext } from './useTabsContext';
import { colors } from '../../theme';

const matchKey: Record<string, string> = {
  users: 'addUser',
  roles: 'addRole',
  inputs: 'addInput',
  documents: 'addDocument',
};

const ActionButtons = () => {
  const { setModalType, isEditMode, customMenuSelected } = useGlobalContext();
  const { tabToUpdate, currentTab, canI } = useTabsContext();

  const customMode =
    customMenuSelected === 'settings' ? matchKey[tabToUpdate?._id || ''] : '';

  const modalType = customMode || (currentTab?._id && 'addEntry') || '';

  const isVisible = canI(currentTab?._id || '', 'create');

  return modalType && isVisible && !isEditMode && tabToUpdate ? (
    <Button
      backgroundColor={`${colors.main}.500`}
      color='white'
      mr={3}
      onClick={() => setModalType(modalType)}
    >
      Ajouter
    </Button>
  ) : null;
};

export default ActionButtons;
