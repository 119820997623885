import { useEffect, useRef, useState } from 'react';
import RSelect, { InputActionMeta } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Box, Flex } from '@chakra-ui/react';
import { getAddress, autocomplete } from '../../api/tabsApi';
import { addressFormat } from '../../helpers';

type TAutoComplete = {
  setFormData: (data: any) => void;
  value: string;
  label: string;
  noPlaceId?: boolean;
  setPending?: (val: boolean) => void;
  isDisabled?: boolean;
  type?: 'street' | 'city' | 'postal';
};

let timeoutAutocomplete: any = null;

const AddressAutocomplete = ({
  setFormData,
  value,
  label,
  setPending,
  isDisabled,
  type,
}: TAutoComplete) => {
  const [predictions, setPredictions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<any>(value);

  const autocompleteToken = useRef(uuidv4());
  const autocompleteTokenCount = useRef(0);

  const ref = useRef<HTMLInputElement>();

  const loadOptions = async (value: any) => {
    if (
      autocompleteTokenCount.current > 0 &&
      autocompleteTokenCount.current % 10 === 0
    ) {
      autocompleteToken.current = uuidv4();
    }

    autocompleteTokenCount.current++;

    const response =
      value && (await autocomplete(value, autocompleteToken.current));

    setPredictions(
      response?.predictions?.map((p: any) => ({
        value: p.place_id,
        label: p.description.replace(', France', ''),
      })),
    );
  };

  const handleInputChange = (val: string, event?: InputActionMeta) => {
    if (event?.action && !/input-change/.test(event?.action)) return;

    setInputValue(val);

    setFormData((d: any) => ({
      ...d,
      [`${label}_${type}`]: val,
      [`${label}_addressDescription`]: null,
      [label]: null,
    }));

    clearTimeout(timeoutAutocomplete);
    timeoutAutocomplete = setTimeout(() => {
      loadOptions(val);
    }, 300);
  };

  const handleOnChange = async (prediction: any) => {
    setPending?.(true);
    setPredictions([]);

    const resp = await getAddress(prediction.value);

    const { street, locality, administrative_area_level_2, postal_code } =
      resp.data;

    const addr = {
      street,
      city: locality,
      postal: postal_code,
    };

    setFormData((d: any) => ({
      ...d,
      [`${label}_street`]: street,
      [`${label}_city`]: locality,
      [`${label}_dept`]: administrative_area_level_2,
      [`${label}_postal`]: postal_code,
      [`${label}`]: prediction.value,
      [`${label}_addressDescription`]: addressFormat(addr),
    }));

    ref.current?.focus();
    ref.current?.blur();

    setPending?.(false);
  };

  useEffect(() => {
    value !== inputValue && setInputValue(value);
  }, [value]);

  const customStyles = isDisabled
    ? {
        input: (base: any) => ({
          ...base,
          visibility: 'visible',
          color: '#999999',
        }),
      }
    : {};

  return (
    <Flex alignItems='center'>
      <Box flexGrow={1}>
        <RSelect
          isDisabled={isDisabled}
          ref={ref as any}
          blurInputOnSelect
          options={predictions}
          onInputChange={(val, event) => handleInputChange(val, event)}
          filterOption={() => true}
          onChange={handleOnChange}
          styles={customStyles}
          inputValue={value}
        />
      </Box>
    </Flex>
  );
};

export default AddressAutocomplete;
