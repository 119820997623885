import { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import { useGlobalContext } from '../../useGlobalContext';
import { TTab, useTabsContext } from '../tabs/useTabsContext';
import { CirlceIcon, allIcons } from '../utils/Icon';
import { colors } from '../../theme';
import DragAndDropList from '../dragAndDrop/DragAndDropList';
import { updateTabOrder } from '../../api/tabsApi';

type TMenuProps = {
  tab: Partial<TTab>;
  isSelected: boolean;
  isHovered: boolean;
  onClick?: any;
  isOpen?: boolean;
  isCustom?: boolean;
};

const MenuItem = ({
  tab: { _id: tabId, title, icon, isMenu, parent_id, menuChilds, cloneId },
  isSelected,
  onClick,
  isHovered,
  isOpen,
  isCustom,
}: TMenuProps) => {
  const { setModalType, isEditMode } = useGlobalContext();
  const { dataCount } = useTabsContext();
  const hasCount = dataCount[`${tabId}`] > 0;

  return (
    <Flex
      p={3}
      _hover={{ backgroundColor: `${colors.main}.200` }}
      cursor='pointer'
      alignItems='center'
      height={50}
      onClick={() => {
        onClick();
      }}
      backgroundColor={
        isSelected
          ? `${colors.main}.100`
          : parent_id
          ? `${colors.main}.700`
          : ''
      }
      color={isSelected ? `${colors.main}.800` : 'white'}
      transition='background .5s ease'
      margin={isHovered ? 'normal' : 'auto'}
      pl={isHovered && isMenu ? 6 : 3}
      boxShadow={isSelected ? 'xl' : ''}
      w='100%'
      borderRight={
        !isHovered && (menuChilds?.length || parent_id) ? '4px solid' : ''
      }
      borderRightColor={`${colors.main}.700`}
      mb={isEditMode && !parent_id && menuChilds?.length ? 3 : 0}
      position='relative'
    >
      <Icon
        as={allIcons[icon || 'HiFolder']}
        boxSize={26}
        style={
          !isHovered && hasCount ? { position: 'absolute', bottom: 7 } : {}
        }
      />
      {isHovered && (
        <Box
          p={1}
          px={2}
          textAlign='center'
          textTransform='capitalize'
          whiteSpace='nowrap'
          fontWeight='bold'
        >
          {/* {title.slice(0, 10)} */}
          {title}
        </Box>
      )}
      {hasCount && (
        <Tag
          borderRadius='100%'
          fontSize='.8em'
          style={
            isHovered
              ? {}
              : {
                  position: 'absolute',
                  top: 5,
                  right: 3,
                }
          }
        >{`${dataCount[`${tabId}`]}`}</Tag>
      )}
      {!isHovered && (
        <Tooltip label={title} placement='right' textTransform='capitalize'>
          <Box position='absolute' w='100%' h='100%' top={0} left={0} />
        </Tooltip>
      )}
      <Box flexGrow={1} />
      {!isEditMode && !!menuChilds?.length && isHovered && (
        <Icon
          as={isOpen ? allIcons.HiChevronDown : allIcons.HiChevronUp}
          boxSize={15}
        />
      )}
      {!isCustom && isEditMode && (
        <Flex ml={3}>
          {cloneId ? (
            <Tag mr={1}>Clone</Tag>
          ) : (
            <CirlceIcon
              as={allIcons.HiMiniDocumentDuplicate}
              onClick={() => {
                setModalType('duplicateTab');
              }}
            />
          )}
          {!isMenu && (
            <CirlceIcon
              as={allIcons.HiPlusSmall}
              onClick={() => {
                setModalType('addMenuSubTab');
              }}
            />
          )}
          <CirlceIcon
            as={allIcons.HiPencilSquare}
            onClick={() => {
              setModalType(isMenu ? 'editMenuSubTab' : 'editTab');
            }}
          />
          <CirlceIcon
            as={allIcons.HiMiniXMark}
            onClick={() => {
              setModalType('delTab');
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

const Elements = ({ tab }: { tab: TTab }) => {
  const { customMenuSelected, isHovered, isEditMode, setCustomMenuSelected } =
    useGlobalContext();
  const { currentTab, getTabs, setCurrentTab, setTabToUpdate } =
    useTabsContext();

  const [index, setIndex] = useState(-1);

  const keepOpen =
    tab._id === currentTab?._id ||
    !!tab.menuChilds?.find((mc) => mc._id === currentTab?._id);

  const handleChange = (tab: TTab) => {
    setCustomMenuSelected('');
    setTabToUpdate(tab);
    setCurrentTab(tab);
  };

  const handleOrderChange = async (tabsOrdered: TTab[]) => {
    await updateTabOrder(tabsOrdered.map((t) => t._id));
    getTabs();
  };

  const El = (
    <Box
      borderBottomColor={`${colors.main}.500`}
      borderBottomWidth={isEditMode || tab.isMenu ? '' : '3px'}
      boxShadow={isEditMode || tab.isMenu ? '' : 'xl'}
    >
      <MenuItem
        key={tab.title}
        tab={tab}
        isSelected={!customMenuSelected && tab._id === currentTab?._id}
        onClick={() => handleChange(tab)}
        isHovered={isHovered}
      />
      {isEditMode ? (
        <DragAndDropList
          setData={handleOrderChange}
          itemsList={tab.menuChilds?.map((tab) => <Elements tab={tab} />) || []}
          finalItems={tab.menuChilds || []}
          typeOfItem='SUBMENU'
        />
      ) : (
        tab.menuChilds?.map((menuChild) => <Elements tab={menuChild} />)
      )}
    </Box>
  );

  if (!isEditMode) {
    return tab.menuChilds?.length ? (
      <Accordion
        borderColor='transparent'
        borderBottomColor={`${colors.main}.500`}
        borderBottomWidth={isEditMode || tab.isMenu ? '' : '3px'}
        boxShadow={isEditMode || tab.isMenu ? '' : 'xl'}
        index={keepOpen ? 0 : index}
      >
        <AccordionItem>
          <AccordionButton
            p={0}
            onMouseEnter={() => setIndex(0)}
            onMouseLeave={() => setIndex(-1)}
          >
            <MenuItem
              key={tab.title}
              tab={tab}
              isSelected={!customMenuSelected && tab._id === currentTab?._id}
              onClick={() => handleChange(tab)}
              isHovered={isHovered}
              isOpen={keepOpen || index >= 0}
            />
          </AccordionButton>
          <AccordionPanel
            p={0}
            onMouseEnter={() => setIndex(0)}
            onMouseLeave={() => setIndex(-1)}
          >
            {tab.menuChilds?.map((menuChild) => (
              <MenuItem
                key={menuChild.title}
                tab={menuChild}
                isSelected={
                  !customMenuSelected && menuChild._id === currentTab?._id
                }
                onClick={() => handleChange(menuChild)}
                isHovered={isHovered}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ) : (
      <Box
        borderBottomColor={`${colors.main}.500`}
        borderBottomWidth={isEditMode || tab.isMenu ? '' : '3px'}
        boxShadow={isEditMode || tab.isMenu ? '' : 'xl'}
      >
        <MenuItem
          key={tab.title}
          tab={tab}
          isSelected={!customMenuSelected && tab._id === currentTab?._id}
          onClick={() => handleChange(tab)}
          isHovered={isHovered}
        />
      </Box>
    );
  }

  return El;
};

const Navbar = () => {
  const {
    isEditMode,
    customMenuSelected,
    isHovered,
    isPlanning,
    isDuplicate,
    onToggle,
    setCustomMenuSelected,
    setModalType,
  } = useGlobalContext();
  const { tabs, settingsTabs, setCurrentTab, setTabToUpdate, getTabs } =
    useTabsContext();

  const handleCustomChange = (title: string) => {
    setCurrentTab(undefined);
    setCustomMenuSelected(title);
    title && localStorage.setItem('currentTab', title);
  };

  const handleOrderChange = async (tabsOrdered: TTab[]) => {
    await updateTabOrder(tabsOrdered.map((t) => t._id));
    getTabs();
  };

  return (
    <Flex
      id='navbar'
      direction='column'
      background={`${colors.main}.400`}
      height='100vh'
      w={isEditMode ? 'auto' : isHovered ? 'auto' : '48px'}
      minW={isHovered ? 'auto' : '48px'}
      boxShadow='dark-lg'
    >
      <Box background={`${colors.main}.600`}>
        <Box
          margin='auto'
          my={3}
          borderRadius={50}
          overflow='hidden'
          boxSize={10}
          flexShrink={0}
        >
          <img src='/logoEVision.png' />
        </Box>
      </Box>
      <Box overflow='auto' flexGrow={1} mt={isEditMode ? 3 : 0}>
        {isEditMode ? (
          <DragAndDropList
            setData={handleOrderChange}
            itemsList={tabs.map((tab) => (
              <Elements tab={tab} />
            ))}
            finalItems={tabs}
            typeOfItem='PAGE'
          />
        ) : (
          tabs.map((tab) => <Elements tab={tab} />)
        )}
        {isEditMode && (
          <CirlceIcon
            as={allIcons.HiPlusSmall}
            backgroundColor={`${colors.main}.100`}
            color={`${colors.main}.800`}
            m={3}
            onClick={() => {
              setModalType('addTab');
            }}
          />
        )}
      </Box>
      <Box backgroundColor={`${colors.main}.600`}>
        {isPlanning && (
          <MenuItem
            tab={{
              title: 'planning',
              icon: 'HiCalendarDays',
            }}
            isSelected={customMenuSelected === 'planning'}
            onClick={() => {
              setTabToUpdate(undefined);
              handleCustomChange('planning');
            }}
            isHovered={isHovered}
            isCustom
          />
        )}
        {isDuplicate && (
          <MenuItem
            tab={{
              title: 'doublons',
              icon: 'HiDocumentDuplicate',
            }}
            isSelected={customMenuSelected === 'duplicate'}
            onClick={() => {
              setTabToUpdate(undefined);
              handleCustomChange('duplicate');
            }}
            isHovered={isHovered}
            isCustom
          />
        )}
        {!isEditMode && !!settingsTabs.length && (
          <MenuItem
            tab={{
              title: 'settings',
              icon: 'HiCog6Tooth',
            }}
            isSelected={customMenuSelected === 'settings'}
            onClick={() => handleCustomChange('settings')}
            isHovered={isHovered}
          />
        )}
        {!isEditMode && (
          <CirlceIcon
            color={`${colors.main}.800`}
            backgroundColor={`${colors.main}.100`}
            as={allIcons[`HiArrow${isHovered ? 'Left' : 'Right'}Circle`]}
            onClick={onToggle}
            margin='auto'
            my={3}
          />
        )}
      </Box>
    </Flex>
  );
};

export default Navbar;
