import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Box,
  SimpleGrid,
  Text,
  GridItem,
  Flex,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Radio,
  Tag,
  Switch,
  Badge,
} from '@chakra-ui/react';
import { allIcons, CirlceIcon } from '../utils/Icon';
import RSelect from 'react-select';
import { useTabsContext } from '../tabs/useTabsContext';
import {
  getHeight,
  getReadableColor,
  isEmpty,
  mergeWithArray,
} from '../../helpers';
import { getDistance, updateAppointment } from '../../api/planningApi';
import { orderBy, values } from 'lodash';
import { colors } from '../../theme';
import { generateGoogleMapsURL } from './MapUrl';
import { useGlobalContext } from '../../useGlobalContext';
import DragDropElement from '../dragAndDrop/DropContainer';
import { customStyle } from '../form/Styles';

const daysName = ['', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
const monthsName = [
  '',
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const CalendarColumnHeader = ({ head }: { head: string }) => (
  <GridItem>
    <Box p={0} bg='blue.100' borderRadius='md' textAlign='center' mb={2}>
      <Text fontWeight='bold'>{head}</Text>
    </Box>
  </GridItem>
);

const HourCheck = ({ hour, onCheck, selected }: any) => {
  const [currMinutes, setCurrMinutes] = useState(0);

  useEffect(() => {
    setCurrMinutes(Number(selected));
  }, [selected]);

  return (
    <>
      {['00', '15', '30', '45'].map((minutes) => (
        <Tag
          mr={1}
          backgroundColor={
            currMinutes === Number(minutes)
              ? `${colors.main}.300`
              : `${colors.main}.100`
          }
          onClick={(e) => {
            e.stopPropagation();
            const min = Number(minutes);
            setCurrMinutes(min);
            onCheck?.((date: Moment) => {
              return moment(date)?.minutes(min || 0);
            });
          }}
        >{`${hour}h${minutes}`}</Tag>
      ))}
    </>
  );
};

type TAppointments = {
  assignedUser: string;
  planningAddress_city: string;
  planningAddress_dept: string;
  planningAddress_postal: string;
  planningAddress_street: string;
  planningDate: string;
  rowData: Record<string, any>;
  user: Record<string, any>;
};

type TCalendarColumnProps = {
  appointments: Record<string, TAppointments[]>;
  day: Moment;
  onCheck?: (date: Moment) => void;
  selected?: Moment;
  selectedUsers: string[];
  isHover?: string;
  setIsHover?: any;
};

const DistanceTag = ({ appoint }: any) => {
  const { rowData } = useTabsContext();
  const { modalType, planningAddress, isPlanningEdit } = useGlobalContext();

  const [distance, setDistance] = useState<any>(null);
  const key = planningAddress || 'planningAddress';

  useEffect(() => {
    if (!appoint.planningAddress || !rowData[key]) return;
    if (!isPlanningEdit && !/addToPlanning|updatePlanning/i.test(modalType))
      return;

    const getIt = async () => {
      if (appoint.planningAddress !== rowData[key]) {
        const resp = await getDistance({
          origin: appoint.planningAddress,
          destination: rowData[key],
        });

        if (resp.data.distance === 0) return;

        setDistance(resp.data);
      }
    };

    getIt();
  }, [rowData]);

  useEffect(() => {
    if (!rowData[key]) {
      setDistance(null);
    }
  }, [rowData]);

  return distance ? (
    <Tag
      fontSize='12px'
      mr={1}
      backgroundColor={/H/.test(distance.duration) ? 'red.400' : 'green.400'}
    >
      {`${distance.duration}/${distance.distance}`}
    </Tag>
  ) : null;
};

const CalendarColumn = ({
  appointments,
  day,
  onCheck,
  selected,
  isHover,
  setIsHover,
}: // selectedUsers,
TCalendarColumnProps) => {
  const { rowData, setRowData, canI } = useTabsContext();
  const { modalType, fetchAppointments, isPlanningEdit, setModalType } =
    useGlobalContext();

  const displayAllSlots = onCheck || isPlanningEdit;
  const isUpdate = modalType === 'updatePlanning';

  useEffect(() => {
    isUpdate && onCheck?.(moment(rowData.planningDate));
  }, []);

  return (
    <GridItem>
      {Array(13)
        .fill('')
        .map((a, idx) => {
          const appoints = orderBy(
            appointments?.[`${8 + idx}`],
            (appoint) => appoint.planningDate,
          );
          // const appointsBefore = appointments?.[`${8 + idx - 1}`];
          const isSelected = selected?.isSame(
            `${moment(day).format('YYYY-MM-DD')} ${8 + idx}:00:00`,
            'hour',
          );

          // const isAvailable = (!selectedUsers.length && appoints) ||
          // const isAvailable =
          //   !appoints?.find((appoint) =>
          //     selectedUsers.includes(appoint.assignedUser),
          //   ) &&
          //   !appointsBefore?.find((appoint) =>
          //     selectedUsers.includes(appoint.assignedUser),
          //   );

          if (!displayAllSlots && !appoints?.length) return null;

          return (
            <Box
              my={displayAllSlots ? 1 : 0}
              p={displayAllSlots ? 1 : 0}
              backgroundColor={displayAllSlots ? `${colors.main}.50` : 'none'}
              borderWidth={displayAllSlots ? '1px' : 0}
              borderColor={displayAllSlots ? `${colors.main}.300` : 'none'}
              borderRadius={5}
            >
              {displayAllSlots && (
                <Flex
                  cursor={onCheck ? 'pointer' : ''}
                  onClick={() => {
                    const date = moment(day);
                    date.set('hour', 8 + idx);
                    onCheck?.(date);
                  }}
                  backgroundColor={
                    isSelected ? `${colors.main}.600` : `${colors.main}.50`
                  }
                  _hover={{
                    backgroundColor: isSelected
                      ? `${colors.main}.600`
                      : `${colors.main}.100`,
                  }}
                  borderRadius={5}
                  alignItems='center'
                  px={2}
                  py={0.5}
                >
                  {!isSelected && <Text fontWeight={500}>{`${8 + idx}H`}</Text>}
                  {onCheck && (
                    <Flex flexGrow={1}>
                      {isSelected && (
                        <Box onClick={(e) => e.stopPropagation()}>
                          <HourCheck
                            hour={8 + idx}
                            onCheck={onCheck}
                            selected={selected?.format('mm')}
                          />
                        </Box>
                      )}
                      <Box flexGrow={1} />
                      <Radio isChecked={isSelected} />
                    </Flex>
                  )}
                  {!onCheck && isPlanningEdit && (
                    <DragDropElement
                      isMinutes
                      noDrag
                      id={`${day.format('YYYY-MM-DD')} ${8 + idx}`}
                      isHover={isHover}
                      setIsHover={setIsHover}
                      onDrop={async (id: string, hour: string) => {
                        await updateAppointment(id, { planningDate: hour });
                        fetchAppointments();
                      }}
                    />
                  )}
                </Flex>
              )}
              {appoints?.map((appoint: any) => (
                <Accordion
                  allowToggle
                  mb={1}
                  defaultIndex={onCheck ? [] : 0}
                  // defaultIndex={0}
                >
                  <AccordionItem position='relative' border='none' mt={2}>
                    <DragDropElement
                      id={appoint._id}
                      noDrag={
                        !isPlanningEdit || appoint.isConfirmed || isUpdate
                      }
                      onDrag={() => setRowData(appoint)}
                      onDrop={() => {
                        setIsHover('');
                        setRowData({});
                      }}
                    >
                      <AccordionButton
                        px={1}
                        height={6}
                        backgroundColor={appoint.user?.userColor}
                        color={getReadableColor(appoint.user?.userColor)}
                        borderRadius='5px 5px 0 0'
                        _hover={{
                          borderColor: appoint.user?.userColor,
                          opacity: 0.8,
                        }}
                      >
                        <Flex w='100%' alignItems='center'>
                          <Text fontWeight='bold' mr={2}>
                            {moment(appoint.planningDate)
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                          <Text textTransform='capitalize'>
                            {appoint.user?.name}
                          </Text>
                          <Box flexGrow={1} />
                          <DistanceTag appoint={appoint} />
                          {appoint.planningAddress_postal}
                          <CirlceIcon
                            as={allIcons.HiCheck}
                            size='18px'
                            backgroundColor={
                              appoint.isConfirmed ? 'green.400' : 'gray.400'
                            }
                            _hover={{ backgroundColor: 'auto' }}
                            cursor='pointer'
                          />
                        </Flex>
                      </AccordionButton>
                    </DragDropElement>
                    <AccordionPanel
                      backgroundColor='white'
                      border='1px solid'
                      borderColor='gray.200'
                      borderRadius={5}
                      borderTopWidth={0}
                      pt={2}
                      mt={-1}
                      pb={1}
                    >
                      <Flex>
                        <Text fontWeight='bold' mr={1}>
                          Adresse
                        </Text>
                        :
                        <Text
                          ml={1}
                        >{`${appoint.planningAddress_street} ${appoint.planningAddress_city} ${appoint.planningAddress_postal}`}</Text>
                      </Flex>
                      {appoint.rowData.map((data: any) => (
                        <Flex>
                          <Text fontWeight='bold' mr={1}>
                            {data.label}
                          </Text>
                          :
                          {data.color ? (
                            <Badge
                              bgColor={data.color}
                              my='auto'
                              borderRadius={5}
                              ml={1}
                              color={getReadableColor(data.color)}
                            >
                              {data.value}
                            </Badge>
                          ) : (
                            <Text ml={1}>{data.value}</Text>
                          )}
                        </Flex>
                      ))}
                      <Flex py={1}>
                        <Box flexGrow={1} />
                        <Tooltip label='Y aller'>
                          <span>
                            <CirlceIcon
                              as={allIcons.HiMapPin}
                              onClick={() =>
                                generateGoogleMapsURL(
                                  appoint.planningAddressDescription,
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                        {canI('planning', 'confirm') && (
                          <Tooltip
                            label={
                              appoint.isConfirmed ? 'Déconfirmer' : 'Confirmer'
                            }
                          >
                            <span>
                              <CirlceIcon
                                as={allIcons.HiCheck}
                                backgroundColor={
                                  appoint.isConfirmed ? 'green.400' : 'gray.400'
                                }
                                _hover={{ backgroundColor: 'auto' }}
                                cursor='pointer'
                                onClick={async () => {
                                  await updateAppointment(appoint._id, {
                                    isConfirmed: !appoint.isConfirmed,
                                  });
                                  await fetchAppointments();
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        {canI('planning', 'update') && (
                          <>
                            <Tooltip label='Modifier RDV'>
                              <span>
                                <CirlceIcon
                                  as={allIcons.HiCalendarDays}
                                  onClick={() => {
                                    setRowData(appoint);
                                    setModalType('updatePlanning');
                                  }}
                                />
                              </span>
                            </Tooltip>
                            <Tooltip label='Modifier dossier'>
                              <span>
                                <CirlceIcon
                                  as={allIcons.HiPencilSquare}
                                  onClick={() => {
                                    setRowData(appoint.formData || {});
                                    setModalType('editEntry');
                                  }}
                                />
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {canI('planning', 'delete') && (
                          <Tooltip label='Supprimer le rdv'>
                            <span>
                              <CirlceIcon
                                as={allIcons.HiMiniXMark}
                                onClick={() => {
                                  setRowData(appoint);
                                  setModalType('deletePlanning');
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </Box>
          );
        })}
    </GridItem>
  );
};

const Calendar = ({
  selectedDate,
  setSelectedDate,
  datas,
}: {
  selectedDate?: Moment;
  setSelectedDate?: (date: Moment) => void;
  datas?: Record<string, any>;
}) => {
  const { appointments, fetchAppointments, isPlanningEdit, setIsPlanningEdit } =
    useGlobalContext();
  const { planningTab, userList, getUsersList, canI } = useTabsContext();

  const [currWeek, setCurrWeek] = useState(moment().startOf('isoWeek'));
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [filterType, setFilterType] = useState<string>();
  const [height, setHeight] = useState(0);
  const [isHover, setIsHover] = useState<any>('');

  const { assignedUser, planningType } = datas || {};

  const days = Array(5)
    .fill('')
    .map((d, idx) => moment(currWeek).add(idx, 'day'));

  const changeWeek = (moins?: boolean) => {
    setCurrWeek((c) =>
      moment(c)[moins ? 'subtract' : 'add'](1, 'week').startOf('isoWeek'),
    );
  };

  const updateUsers = (newSelected: any[]) => {
    if (!newSelected.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(newSelected);
    }
  };

  useEffect(() => {
    fetchAppointments();
    getUsersList();
  }, []);

  useEffect(() => {
    if (!assignedUser) return;

    const currUser = userList.find((option) => option._id === assignedUser);
    currUser &&
      setSelectedUsers([{ value: currUser._id, label: currUser.name }]);
  }, [assignedUser]);

  useEffect(() => {
    if (isEmpty(planningType)) return;
    setFilterType(planningType);
  }, [planningType]);

  useEffect(() => {
    const height = getHeight(
      '#calendarContainer',
      'top',
      '#mainContainer',
      'paddingBottom',
    );

    setHeight(height);
  }, [window.innerWidth, window.innerHeight]);

  const currAppoints =
    filterType != null
      ? appointments[filterType]
      : values(appointments).reduce(
          (prev, val) => mergeWithArray(prev, val),
          {},
        );

  const filteredAppoint = !currAppoints
    ? []
    : !selectedUsers?.length
    ? values(currAppoints).reduce((prev, val) => mergeWithArray(prev, val), {})
    : selectedUsers.reduce(
        (prev, user) => mergeWithArray(prev, currAppoints[user.value]),
        {},
      );

  const allUsers = userList
    .filter((u) => u.isPlanable)
    .map((u) => ({
      value: u._id,
      label: u.name,
    }));

  const planningTypeOptions = planningTab?.planningType?.options || [];

  return (
    <>
      <Flex mb={3} alignItems='center' userSelect='none'>
        <CirlceIcon
          as={allIcons.HiArrowLeftCircle}
          onClick={() => changeWeek(true)}
        />
        <Text textAlign='center' w={200} fontWeight='bold' fontSize='1.5em'>
          {monthsName[currWeek.format('M') as any]}
        </Text>
        <CirlceIcon
          as={allIcons.HiArrowRightCircle}
          onClick={() => changeWeek(false)}
        />
        <Tooltip label='Semaine en cours' placement='right'>
          <span>
            <CirlceIcon
              mx={3}
              as={allIcons.HiCalendarDays}
              onClick={() => setCurrWeek(moment().startOf('isoWeek'))}
            />
          </span>
        </Tooltip>
        <Text mx={3}>Assigné à :</Text>
        <RSelect
          placeholder='Tous les utilisateurs'
          styles={customStyle}
          closeMenuOnSelect={false}
          isMulti
          value={selectedUsers.length ? selectedUsers : []}
          onChange={updateUsers as any}
          options={allUsers}
        />
        {planningTypeOptions.length && (
          <>
            <Text mx={3}>Type de planning :</Text>
            <RSelect
              placeholder='Tous les planning'
              styles={customStyle}
              value={
                filterType == null
                  ? undefined
                  : {
                      value: filterType,
                      label: planningTypeOptions[filterType],
                    }
              }
              onChange={(e: any) => setFilterType(e?.value)}
              options={planningTypeOptions.map(
                (option: string, idx: number) => ({
                  label: option,
                  value: idx,
                }),
              )}
              isClearable
            />
          </>
        )}
        {!setSelectedDate && canI('planning', 'update') && (
          <Flex alignItems='center'>
            <Text ml={4} mr={2}>
              Modifier les RDV
            </Text>
            <Switch
              isChecked={isPlanningEdit}
              onChange={(e) => setIsPlanningEdit(e.target.checked)}
            />
          </Flex>
        )}
      </Flex>
      <SimpleGrid columns={5} spacing={4}>
        {days.map((day) => (
          <CalendarColumnHeader
            key={day.format('DD/MM/YYYY')}
            head={`${daysName[day.format('d') as any]} ${day.format('D')}`}
          />
        ))}
      </SimpleGrid>
      <SimpleGrid
        columns={5}
        spacing={4}
        id='calendarContainer'
        maxH={`calc(100vh - ${height}px)`}
        overflow='auto'
      >
        {days.map((day) => (
          <CalendarColumn
            key={`index-${day.format('DD/MM/YYYY')}`}
            appointments={filteredAppoint[day.format('DD/MM/YYYY')]}
            onCheck={setSelectedDate}
            selected={selectedDate}
            day={day}
            selectedUsers={selectedUsers.map(({ value }) => value)}
            isHover={isHover}
            setIsHover={setIsHover}
          />
        ))}
      </SimpleGrid>
    </>
  );
};

export default Calendar;
