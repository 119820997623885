import { Text } from '@chakra-ui/react';
import { addInput, deleteInput, updateInput } from '../../../api/tabsApi';
import { useGlobalContext } from '../../../useGlobalContext';
import { useTabsContext } from '../../tabs/useTabsContext';
import useForm, { TField } from '../../form/Form';
import { useEffect, useMemo } from 'react';
import { entries } from 'lodash';

export const addressInputs = ['rue', 'code postal', 'ville', 'departement'];
export const addressInputskeys = ['street', 'postal', 'city', 'dept'];
export const addressInputsRoles = [
  'street',
  'postal',
  'city',
  'dept',
  'showValidation',
];

const typesOfInput = [
  { label: 'Text', value: 'text' },
  { label: 'Nombre', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Liste', value: 'select' },
  { label: 'Liste multiple', value: 'multiselect' },
  { label: 'Adresse', value: 'address' },
  { label: 'Téléphone', value: 'tel' },
  { label: 'Utilisateur', value: 'users' },
  { label: 'Oui/Non', value: 'switch' },
];

export const typesOfInputOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Nombre', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Liste', value: 'select' },
  { label: 'Liste multiple', value: 'multiselect' },
  { label: 'Adresse rue', value: 'adresse_street' },
  { label: 'Adresse ville', value: 'adresse_city' },
  { label: 'Adresse code postal', value: 'adresse_postal' },
  { label: 'Téléphone', value: 'tel' },
  { label: 'Utilisateur', value: 'users' },
];

const getFields = (modalType: string, userList: any[]): TField[] => [
  {
    type: 'select',
    name: 'type',
    label: 'Type de champ',
    options: typesOfInput,
  },
  {
    type: 'text',
    label: 'Nom du champ',
    name: 'label',
    condition: (data) => data?.type,
  },
  {
    type: 'text',
    label: 'ID du champ (si Nom existe déjà)',
    name: 'name_id',
    // condition: (data) => data?.type && modalType === 'addInput',
  },
  {
    type: 'text',
    label: 'Unité',
    name: 'unity',
    condition: (data) => data?.type === 'number',
  },
  {
    type: 'selectInput',
    label: 'Éditer la liste',
    name: 'options-colors',
    condition: (data) => /select/.test(data?.type),
  },
  ...addressInputs.map(
    (input, idx) =>
      ({
        type: 'checkbox',
        label: input,
        name: addressInputskeys[idx],
        condition: (data) => data?.type && data?.type === 'address',
      } as TField),
  ),
  {
    type: 'checkbox',
    label: 'Est valide',
    name: 'showValidation',
    condition: (data) => data?.type && data?.type === 'address',
  },
  {
    type: 'multiSelect',
    label: 'Utilisateurs',
    name: 'users',
    options: userList.map((user) => ({
      label: user.name,
      value: user._id,
    })) as any,
    condition: (data) => data?.type && data?.type === 'users',
  },
  {
    type: 'checkbox',
    label: 'Champ obligatoire',
    name: 'required',
    condition: (data) => data?.type,
  },
  {
    type: 'createSelect',
    label: 'Groupe',
    name: 'group',
    condition: (data) => data?.type,
  },
];

const useManageInputs = () => {
  const { userList, getInputs, rowData, setRowData, canI } = useTabsContext();
  const { onClose, modalType } = useGlobalContext();

  const fields = useMemo(
    () => getFields(modalType, userList),
    [modalType, userList],
  );

  const { Form, formData } = useForm(fields);

  useEffect(() => {
    const regex =
      formData.type === 'address'
        ? /_id|type|label|name_id|group|required|showValidation|street|city|postal|dept/
        : /select/.test(formData.type)
        ? /_id|type|label|name_id|group|required|colors|options/
        : formData.type === 'number'
        ? /_id|type|label|name_id|unity|group|required/
        : formData.type === 'users'
        ? /_id|type|label|name_id|unity|group|options|required/
        : /_id|type|label|name_id|group|required/;

    const data = entries(formData).reduce(
      (prev, [k, v]) => ({
        ...prev,
        [k]: regex.test(k) ? v : null,
      }),
      {},
    );

    !/del/.test(modalType) && setRowData(data);
  }, [formData.type]);

  const handleAddInput = async () => {
    formData.options = formData.options?.filter((f: string) => f);

    await addInput(formData);
    await getInputs(true);
    onClose();
  };

  const handleEditInput = async () => {
    await updateInput(formData);
    await getInputs(true);
    onClose();
  };

  const handleDeleteInput = async () => {
    await deleteInput(rowData._id);
    await getInputs(true);
    onClose();
  };

  const renderManageInputs = Form;

  const deletePermission = canI('inputs', 'delete');

  const renderDeleteInput = (
    <Text>Confirm you want to delete this entry ?</Text>
  );

  return {
    addInput: {
      component: renderManageInputs,
      action: handleAddInput,
      title: 'Add input',
      actionLabel: 'Add',
    },
    editInput: {
      component: renderManageInputs,
      canDelete: deletePermission ? 'deleteInput' : '',
      action: handleEditInput,
      title: 'Edit input',
      actionLabel: 'Edit',
    },
    deleteInput: {
      component: renderDeleteInput,
      action: handleDeleteInput,
      title: 'Delete input',
      actionLabel: 'Confirm',
    },
  };
};

export default useManageInputs;
