import { Button, Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { deleteTabFilters, updateTabFilters } from '../../../api/tabsApi';
import { useGetSelectFromId } from '../../../helpers';
import useForm from '../../form/Form';
import { useTabsContext } from '../../tabs/useTabsContext';
import { uniqBy } from 'lodash';

const useManageFilters = () => {
  const { currentTab, subTabs, getTabs } = useTabsContext();
  const getSelectFromId = useGetSelectFromId();
  const { Form, formData, setFormData } = useForm(
    uniqBy(
      subTabs?.flatMap((tab) => {
        return tab.tableColumns
          ?.filter((f) => /select/.test(f.type))
          .map((f) => ({ ...f, type: 'select' }));
      }),
      (f) => f._id,
    ),
  );

  const handleEditFilters = async (input: any) => {
    const filter = input.props.field.name_id;
    await updateTabFilters(currentTab?._id, {
      filter,
      value: formData[filter],
    });
    await getTabs();
    setFormData({});
  };

  const handleDeleteFilters = async (f: any) => {
    await deleteTabFilters(currentTab?._id, f._id);
    await getTabs();
    setFormData({});
  };

  const renderManageFilters = (
    <>
      {currentTab?.filters?.map((f: any) => (
        <Tag
          key={f._id}
          size='xl'
          borderRadius='full'
          variant='solid'
          colorScheme='blue'
          p={3}
          mr={3}
          mb={3}
        >
          <TagLabel>{`${getSelectFromId(f.filter)?.label || f.filter}: ${
            getSelectFromId(f.filter)?.options?.[Number(f.value)] ||
            f.value ||
            'vide'
          }`}</TagLabel>
          <TagCloseButton onClick={() => handleDeleteFilters(f)} />
        </Tag>
      ))}
      {Form?.map((input: any) => {
        return (
          <Flex>
            {input}
            <Button mb={3} ml={3} onClick={() => handleEditFilters(input)}>
              Add
            </Button>
          </Flex>
        );
      })}
    </>
  );

  return {
    editFilters: {
      component: renderManageFilters,
      title: 'Edit filters',
      actionLabel: 'Edit',
    },
  };
};

export default useManageFilters;
