import { Text } from '@chakra-ui/react';
import { entries } from 'lodash';
import {
  deleteDatas,
  deleteInputs,
  deleteRoles,
  deleteUsers,
  patchData,
  updateInputs,
  updateUsers,
} from '../../../api/tabsApi';
import useForm from '../../form/Form';
import { useGlobalContext } from '../../../useGlobalContext';
import { TColumnData, useTabsContext } from '../../tabs/useTabsContext';

const useManageActions = () => {
  const {
    tabToUpdate,
    currentTab,
    subTabs,
    rowSelected,
    roleList,
    rowData,
    setRowSelected,
    getUsersList,
    getRolesList,
    getInputs,
    getData,
    canI,
  } = useTabsContext();

  const { isSuper, isAdmin, onClose } = useGlobalContext();

  const inputs = subTabs.reduce(
    (prev, tab) => [
      ...prev,
      ...tab.tableColumns
        .filter(
          (col) =>
            /select|users/.test(col.type) &&
            !prev.find((p) => p._id === col._id),
        )
        .map((col) => ({
          ...col,
          parent_id: tab._id,
          type: col.type === 'text' ? '' : col.type,
        })),
    ],
    [] as TColumnData[],
  );

  const options = roleList?.map((role) => ({
    label: role.name,
    value: role._id,
  }));

  const userInputs = [
    {
      label: 'Role',
      name: 'role_id',
      type: 'select',
      options,
      selected: rowData.role_id,
      condition: () => !isSuper,
    },
    {
      label: 'Admin',
      name: 'isAdmin',
      type: 'checkbox',
      condition: () => isSuper || isAdmin,
      forced: true,
    },
    {
      label: 'Planifiable',
      name: 'isPlanable',
      type: 'checkbox',
      forced: true,
    },
    { label: 'Désactivé', name: 'blocked', type: 'checkbox', forced: true },
  ];

  const inputsInputs = [
    {
      type: 'createSelect',
      label: 'Groupe',
      name: 'group',
    },
  ];

  const { Form: FormInputs, formData: formDataInputs } = useForm(inputsInputs);
  const { Form: FormUsers, formData: formDataUsers } = useForm(userInputs);
  const { Form, formData } = useForm(inputs);

  const handleAction = async () => {
    const ids = entries(rowSelected).reduce(
      (prev, [k, v]) => (!v ? prev : [...prev, k]),
      [] as string[],
    );

    switch (true) {
      case /inputs/.test(tabToUpdate?._id || ''):
        await updateInputs({ ids, ...formDataInputs });
        await getInputs(true);
        break;
      // case /roles/.test(tabToUpdate?._id || ''):
      //   await deleteRoles(ids);
      //   await getRolesList(true);
      //   break;
      case /users/.test(tabToUpdate?._id || ''):
        await updateUsers({ ids, ...formDataUsers });
        await getUsersList(true);
        break;
      default:
        await patchData({ ids, ...formData });
        await getData();
    }

    setRowSelected({});
    onClose();
  };

  const handleDeleteAction = async () => {
    const ids = entries(rowSelected).reduce(
      (prev, [k, v]) => (!v ? prev : [...prev, k]),
      [] as string[],
    );

    switch (true) {
      case /inputs/.test(tabToUpdate?._id || ''):
        await deleteInputs(ids);
        await getInputs(true);
        break;
      case /roles/.test(tabToUpdate?._id || ''):
        await deleteRoles(ids);
        await getRolesList(true);
        break;
      case /users/.test(tabToUpdate?._id || ''):
        await deleteUsers(ids);
        await getUsersList(true);
        break;
      default:
        await deleteDatas(ids);
        await getData();
    }

    setRowSelected({});
    onClose();
  };

  const deletePermission =
    isAdmin || (currentTab?._id && canI(currentTab?._id, 'delete'));

  const renderDeleteInput = (
    <Text>
      Vous confirmez vouloir supprimer toutes les lignes sélectionnés ?
    </Text>
  );

  let renderManageActions: any = Form;

  switch (true) {
    case /inputs/.test(tabToUpdate?._id || ''):
      renderManageActions = FormInputs;
      break;
    // case /roles/.test(tabToUpdate?._id || ''):
    // renderManageActions  =
    // break;
    case /users/.test(tabToUpdate?._id || ''):
      renderManageActions = FormUsers;
      break;
  }

  // renderManageActions = (
  //   <Box>
  //     <Text>Vide = inchangé</Text>
  //     <Text>Rouge = retirer</Text>
  //     <Text>Bleu = </Text>
  //     {renderManageActions}
  //   </Box>
  // );

  return {
    runAction: {
      component: renderManageActions,
      action: handleAction,
      title: 'Actions multiple',
      actionLabel: 'Valider',
      canDelete: deletePermission ? 'deleteAction' : '',
    },
    deleteAction: {
      component: renderDeleteInput,
      action: handleDeleteAction,
      title: 'Supprimer entrées',
      actionLabel: 'Confirmer',
    },
  };
};

export default useManageActions;
