import { useState } from 'react';
import { values } from 'lodash';
import moment from 'moment';
import { Input, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const RdvTable = ({ data }: any) => {
  const [filter, setFilter] = useState('');

  const filteredData = data.filter((rd: any) =>
    values(rd)
      .map((v) => JSON.stringify(v))
      .join(' ')
      .toLowerCase()
      .includes(filter.toLowerCase()),
  );

  return (
    <>
      <Input
        placeholder='Rechercher un champ'
        mb={2}
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
      />
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Date/Heure</Th>
            <Th>Assigné à</Th>
            <Th>Par</Th>
            <Th>Type</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.reverse().map((d: any) => (
            <Tr>
              <Td>{moment(d.planningDate).format('DD/MM/YYYY HH:mm')}</Td>
              <Td>{d.user.name || d.user.login}</Td>
              <Td>{d.createdBy?.name || d.createdBy?.login || '-'}</Td>
              <Td>{d.planningTypeName}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default RdvTable;
