import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import {
  Icon as ChakraIcon,
  IconProps,
  Circle,
  SquareProps,
} from '@chakra-ui/react';
import { entries } from 'lodash';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { BiColumns } from 'react-icons/bi';
import * as hiIcons from 'react-icons/hi2';
import { IoMdLogOut } from 'react-icons/io';
import { colors } from '../../theme';

export const Icon = ({
  color,
  icon,
  ...props
}: IconProps & { icon?: ReactNode }) => (
  <ChakraIcon
    boxSize='30px'
    fontSize='25px'
    color={color || `${colors.main}.500`}
    transition='color 0.2s'
    _hover={
      props.onClick ? { cursor: 'pointer', color: `${colors.main}.300` } : {}
    }
    {...props}
  >
    {icon}
  </ChakraIcon>
);

export const CirlceIcon = ({
  icon,
  color,
  ...props
}: SquareProps & { icon?: ReactNode }) => (
  <Circle
    size='30px'
    fontSize='15px'
    padding='5px'
    color={color || `${colors.main}.800`}
    mx={0.5}
    _hover={
      props.onClick
        ? {
            cursor: 'pointer',
            backgroundColor: props.backgroundColor || `${colors.main}.800`,
            color: `${colors.main}.100`,
          }
        : {}
    }
    {...props}
    backgroundColor={props.backgroundColor || `${colors.main}.100`}
  >
    {icon}
  </Circle>
);

export const icons = {
  logout: IoMdLogOut,
  edit: EditIcon,
  cross: CloseIcon,
  arrowUp: <ChevronUpIcon />,
  arrowDown: <ChevronDownIcon />,
  trash: <DeleteIcon />,
  page: BiColumns,
  column: hiIcons.HiViewColumns,
  duplicate: <hiIcons.HiOutlineDocumentDuplicate />,
};

export const allIcons: Record<string, IconType> = entries(hiIcons).reduce(
  (prev, [key, value]) =>
    /Outline/.test(key) ? prev : { ...prev, [key]: value },
  {},
);
