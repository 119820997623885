import { useCallback, useEffect, useState } from 'react';
import { Text, Input } from '@chakra-ui/react';
import { entries, groupBy } from 'lodash';
import { addRoles, deleteRoles, updateRoles } from '../../../api/tabsApi';
import { useGlobalContext } from '../../../useGlobalContext';
import TreeInput from '../../form/TreeInput';
import { useTabsContext } from '../../tabs/useTabsContext';
import { allIcons } from '../../utils/Icon';
import { addressInputs, addressInputsRoles } from './ManageInputs';

export const rolesActions = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
  { title: 'delete', label: 'supprimer' },
];

export const planningRolesActions = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
  { title: 'delete', label: 'supprimer' },
  { title: 'confirm', label: 'confirmer' },
];

export const rolesActionsWithoutDelete = [
  { title: 'create', label: 'créer' },
  { title: 'read', label: 'voir' },
  { title: 'update', label: 'modifier' },
];

const useManageRoles = () => {
  const { tabs, dataInputs, rowData, getRolesList, canI } =
    useTabsContext() || [];
  const { onClose, modalType } = useGlobalContext() || [];

  const [roleName, setRoleName] = useState(rowData.name);
  const [treeValue, setTreeValue] = useState({});

  useEffect(() => {
    setTreeValue({});
  }, [rowData]);

  const handleTreeChange = useCallback(
    (v: Record<string, boolean>) => {
      setTreeValue((allValues) => ({ ...allValues, ...v }));
    },
    [setTreeValue],
  );

  const handleRole = async () => {
    const submitAction = modalType === 'addRole' ? addRoles : updateRoles;
    await submitAction({
      name: roleName,
      roles: entries(treeValue).reduce(
        (prev, [k, v]) => (!v ? prev : { ...prev, [k]: v }),
        {},
      ),
      _id: rowData?._id,
    });
    await getRolesList(true);
    onClose();
  };

  const handleDeleteRole = async () => {
    await deleteRoles(rowData._id);
    await getRolesList(true);
    onClose();
  };

  const filteredInputs = dataInputs?.filter(
    (i) => !/planning_type/.test(i.name_id as string),
  );

  const groupedData = groupBy(filteredInputs, 'group');

  const renderManageRoles = (
    <>
      <Input
        type={'text'}
        onChange={(e) => setRoleName(e.target.value)}
        defaultValue={rowData.name}
        mb={5}
      />
      <TreeInput
        list={[
          {
            id: 'inputs',
            name: 'Champs',
            childs: entries(groupedData).map(([dataKey, dataInput]) => ({
              id: `inputs-${dataKey}`,
              name: dataKey !== 'undefined' ? dataKey : 'Champs de base',
              childs: dataInput?.flatMap((input) =>
                input.type === 'address'
                  ? addressInputsRoles.map((key, idx) => ({
                      id: `${input.name_id}_${key}`,
                      name: `${addressInputs[idx] || 'Adresse validation'} ${
                        input.label
                      } (${input.name_id})`,
                    }))
                  : {
                      id: input.name_id,
                      name: `${input.label} (${input.name_id})`,
                    },
              ),
            })),
          },
        ]}
        actions={rolesActionsWithoutDelete}
        selected={rowData.roles}
        onChange={handleTreeChange}
      />
      <TreeInput
        list={[
          {
            id: 'tabs',
            name: 'Pages',
            childs: tabs.map((tab) => ({
              id: tab._id,
              name: tab.title,
              icon: tab.icon,
              childs: tab.menuChilds?.map((mc) => ({
                id: mc._id,
                name: mc.title,
                icon: mc.icon,
              })),
            })),
          },
        ]}
        actions={rolesActions}
        selected={rowData.roles}
        onChange={handleTreeChange}
      />
      <TreeInput
        list={[
          { name: 'Planning', id: 'planning', icon: allIcons.HiCalendarDays },
        ]}
        actions={planningRolesActions}
        selected={rowData.roles}
        onChange={handleTreeChange}
      />
      <TreeInput
        list={[
          {
            name: 'Doublons',
            id: 'duplicate',
            icon: allIcons.HiDocumentDuplicate,
          },
        ]}
        actions={[{ title: 'read', label: 'voir' }]}
        selected={rowData.roles}
        onChange={handleTreeChange}
      />
    </>
  );

  const deletePermission = canI('roles', 'delete');

  const renderDeleteRole = <Text>Confirm you want to delete this role ?</Text>;

  return {
    addRole: {
      component: renderManageRoles,
      action: handleRole,
      title: 'Add role',
      actionLabel: 'Add',
    },
    editRole: {
      component: renderManageRoles,
      canDelete: deletePermission ? 'deleteRole' : '',
      action: handleRole,
      title: 'Edit role',
      actionLabel: 'Edit',
    },
    deleteRole: {
      component: renderDeleteRole,
      action: handleDeleteRole,
      title: 'Delete role',
      actionLabel: 'Confirm',
    },
  };
};

export default useManageRoles;
