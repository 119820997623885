import { Box } from '@chakra-ui/react';
import ModalTable from '../modal/ModalTable';
import DynamicTable from '../table/DynamicTable';
import SettingsTabs from '../tabs/SettingsTabs';
import SubTabsWithOrder from '../tabs/SubTabsWithOrder';
import { useGlobalContext } from '../../useGlobalContext';
import { useTabsContext } from '../tabs/useTabsContext';
import Toolbar from './Toolbar';
import Calendar from '../calendar/Calendar';

const Main = () => {
  const { isEditMode, customMenuSelected } = useGlobalContext();
  const { ready } = useTabsContext();

  return (
    <>
      <Box flexGrow={1} h='100vh' overflow='auto'>
        <Toolbar />
        {!ready ? null : (
          <>
            <Box
              padding={7}
              overflow='auto'
              h='calc(100vh - 56px)'
              id='mainContainer'
            >
              {customMenuSelected ? null : isEditMode ? (
                <SubTabsWithOrder />
              ) : (
                <DynamicTable />
              )}
              {!isEditMode && customMenuSelected === 'settings' && (
                <SettingsTabs />
              )}
              {customMenuSelected === 'planning' &&
                (isEditMode ? <SubTabsWithOrder /> : <Calendar />)}
              {customMenuSelected === 'duplicate' &&
                (isEditMode ? <SubTabsWithOrder /> : <DynamicTable />)}
            </Box>
            <ModalTable />
          </>
        )}
      </Box>
    </>
  );
};

export default Main;
