import { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { addUser, deleteUser, updateUser } from '../../../api/tabsApi';
import useForm, { TField } from '../../form/Form';
import { TUser } from '../../login/Login';
import { useGlobalContext } from '../../../useGlobalContext';
import { useTabsContext } from '../../tabs/useTabsContext';
import ColorPicker, { allColors } from '../../form/ColorPicker';
import { getRandom } from '../../../helpers';

const useManageUsers = () => {
  const { getUsersList, roleList, rowData, canI } = useTabsContext();
  const { onClose, modalType, isSuper, isAdmin } = useGlobalContext();

  const [userColor, setUserColor] = useState('');

  const handleAddUser = async (formData: TUser) => {
    const resp = await addUser(formData);

    if (resp) {
      await getUsersList(true);
      onClose();
    }
  };

  const handleEditUser = async (formData: TUser) => {
    const resp = await updateUser(rowData._id, formData);

    if (resp) {
      await getUsersList(true);
      onClose();
    }
  };

  const handleDeleteUser = async () => {
    await deleteUser(rowData._id);
    await getUsersList(true);
    onClose();
  };

  const options = roleList?.map((role) => ({
    label: role.name,
    value: role._id,
  }));

  const fields: TField[] = [
    { label: 'Nom prénom', name: 'name', type: 'text' },
    { label: 'Login', name: 'login', type: 'text' },
    {
      label: 'Pass',
      name: 'pass',
      type: 'password',
      condition: () => modalType === 'editUser',
    },
    {
      label: 'Role',
      name: 'role_id',
      type: 'select',
      options,
      selected: rowData.role_id,
      condition: () => !isSuper,
    },
    {
      label: 'Admin',
      name: 'isAdmin',
      type: 'checkbox',
      condition: () => isSuper || isAdmin,
    },
    { label: 'Planifiable', name: 'isPlanable', type: 'checkbox' },
    { label: 'Désactivé', name: 'blocked', type: 'checkbox' },
    { label: 'Sans restriction', name: 'noRestrict', type: 'checkbox' },
  ];

  const handleSubmit = () => {
    const submitFn = modalType === 'addUser' ? handleAddUser : handleEditUser;
    submitFn({ ...fd, userColor } as any);
  };

  const { Form, formData: fd } = useForm(fields);

  useEffect(() => {
    setUserColor(rowData.userColor || getRandom(allColors));
  }, [rowData.userColor]);

  // useEffect(() => {
  //   setFormData((f) => ({ ...f, login: strNomalize(fd.name) }));
  // }, [fd.name]);

  const deletePermission = canI('users', 'delete');

  const renderManageUsers = (
    <>
      {Form.map((f: any) => {
        return f.props.field.name === 'isPlanable' && fd.isPlanable ? (
          <Box position={'relative'}>
            {f}
            <Box position='absolute' top={0} left='calc(30% + 30px)'>
              <ColorPicker
                bg={userColor}
                onChange={(e) => {
                  setUserColor(e.hex);
                }}
              />
            </Box>
          </Box>
        ) : (
          f
        );
      })}
    </>
  );

  const renderDeleteUser = <Text>Confirm you want to delete this entry ?</Text>;

  return {
    addUser: {
      component: renderManageUsers,
      action: handleSubmit,
      title: 'Add user',
      actionLabel: 'Add',
    },
    editUser: {
      component: renderManageUsers,
      canDelete: deletePermission ? 'deleteUser' : '',
      action: handleSubmit,
      title: 'Edit user',
      actionLabel: 'Edit',
    },
    deleteUser: {
      component: renderDeleteUser,
      action: handleDeleteUser,
      title: 'Delete user',
      actionLabel: 'Confirm',
    },
  };
};

export default useManageUsers;
