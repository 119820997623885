import { flexRender, RowData } from '@tanstack/react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Icon,
  Badge,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useGlobalContext } from '../../../useGlobalContext';
import { colors } from '../../../theme';
import {
  formatDate,
  formatPhoneNumber,
  getReadableColor,
  isEmpty,
} from '../../../helpers';
import { TABLE_FONT_SIZE, TTableContainer } from '../TableContainer';
import Filter from './Filters';
import { useTabsContext } from '../../tabs/useTabsContext';
import { allIcons, CirlceIcon } from '../../utils/Icon';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    _id: string;
  }
}

const TableCrm = ({
  parentRef,
  table,
  topTable,
  totalSize,
  virtualRows,
  minWidths,
  finalWidths,
  noResize,
  isReady,
  onEditClick,
  getHeaderGroups,
  getRowModel,
}: TTableContainer & { noResize?: boolean }) => {
  const { showNbLine, filterActive, isEditMode } = useGlobalContext();
  const { rowSelected, toggleSelected } = useTabsContext();

  const canFilter = !isEditMode && filterActive;

  return (
    <Box
      ref={parentRef}
      id='reactTableContainer'
      overflow='auto'
      h='100%'
      maxH={`calc(100vh - ${topTable}px)`}
      w='100%'
      borderWidth={1}
      borderRadius='md'
      position='relative'
      border='1px solid'
      borderColor='gray.300'
      whiteSpace='nowrap'
      opacity={isReady ? 1 : 0}
    >
      <Table
        fontSize={TABLE_FONT_SIZE}
        w='max-content'
        id='reactTable'
        minW={noResize ? '100%' : 'auto'}
      >
        <Thead
          position='sticky'
          top={-1}
          left={-1}
          zIndex={1}
          backgroundColor={`${colors.main}.600`}
        >
          {getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup?.id}>
              {headerGroup.headers.map((header, idx) => (
                <Th
                  fontSize={TABLE_FONT_SIZE}
                  key={header.column.columnDef.meta?._id}
                  p={0}
                  color='white'
                  colSpan={header.colSpan}
                  style={{
                    minWidth: minWidths[idx],
                    width: finalWidths[idx],
                  }}
                  position='relative'
                >
                  {noResize || (showNbLine && idx === 0) ? null : (
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          table.options.columnResizeDirection
                        } ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                      }}
                    />
                  )}
                  <Flex
                    alignItems='center'
                    direction='column'
                    borderBottomWidth='2px'
                    borderRightWidth='1px'
                    px={3}
                    py={3}
                  >
                    <Box
                      onClick={header.column.getToggleSortingHandler()}
                      cursor='pointer'
                      mb={1}
                      className='header-text'
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {
                        {
                          asc: <Icon as={ChevronUpIcon} />,
                          desc: <Icon as={ChevronDownIcon} />,
                        }[header.column.getIsSorted() as string]
                      }
                    </Box>
                    {canFilter && (
                      <Filter column={header.column} table={table} />
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody
          style={{
            height: `${totalSize}px`,
            position: 'relative',
          }}
        >
          {virtualRows.map((virtualRow, index) => {
            const row = getRowModel().rows[virtualRow.index];
            return (
              row && (
                <Tr
                  key={row.id}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '35px',
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  backgroundColor={index % 2 ? `${colors.main}.50` : 'white'}
                  _hover={{
                    backgroundColor: `${colors.main}.100`,
                    cursor: 'pointer',
                  }}
                >
                  {row?.getVisibleCells().map((cell, idx) => {
                    const meta = cell.column.columnDef.meta as any;
                    const color = meta?.colors?.[row.original[meta?.colorKey]];

                    return (
                      <Td
                        px={meta.tableIndex ? 0 : 3}
                        py={0}
                        textOverflow={
                          !/select|users/.test(meta.type) ? 'ellipsis' : ''
                        }
                        key={cell?.id}
                        style={{
                          minWidth: minWidths[idx],
                          width: finalWidths[idx],
                          maxWidth: finalWidths[idx],
                        }}
                        overflow={'hidden'}
                        fontWeight={color ? 'bold' : 'auto'}
                        textAlign='center'
                        height='35px'
                        cursor={
                          !meta.tableIndex && meta.type !== 'tel'
                            ? 'pointer'
                            : 'default'
                        }
                        onClick={
                          !meta.tableIndex && meta.type !== 'tel'
                            ? () => onEditClick(row)
                            : () => {}
                        }
                      >
                        {meta.isMulti ? (
                          row.original[meta.colorKey]?.map((valIdx: number) => (
                            <Badge
                              key={`${valIdx}`}
                              px={1.5}
                              py={1}
                              ml={1}
                              backgroundColor={meta?.colors[valIdx] || 'auto'}
                              color={
                                meta?.colors[valIdx]
                                  ? getReadableColor(meta?.colors[valIdx])
                                  : 'black'
                              }
                              borderRadius={10}
                              fontSize={TABLE_FONT_SIZE}
                            >
                              {meta.options[valIdx]}
                            </Badge>
                          )) || '-'
                        ) : meta.showValidation ? (
                          <Flex justifyContent='center'>
                            {`${cell.renderValue()}` === 'non' && (
                              <Tooltip
                                label='Adresse non trouvé'
                                placement='left'
                              >
                                <span>
                                  <CirlceIcon
                                    as={
                                      allIcons[
                                        `${cell.renderValue()}` === 'oui'
                                          ? 'HiCheck'
                                          : 'HiMiniExclamationTriangle'
                                      ]
                                    }
                                    color={
                                      `${cell.renderValue()}` === 'oui'
                                        ? 'auto'
                                        : 'orange.400'
                                    }
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </Flex>
                        ) : meta.tableIndex ? (
                          <>
                            <Checkbox
                              mr={1}
                              isChecked={rowSelected[row.original._id] || false}
                              onChange={() => toggleSelected(row.original._id)}
                            />
                            {cell.renderValue()}
                          </>
                        ) : color ? (
                          <Badge
                            px={1.5}
                            py={1}
                            backgroundColor={color || 'auto'}
                            color={color ? getReadableColor(color) : 'black'}
                            borderRadius={10}
                            fontSize={TABLE_FONT_SIZE}
                          >{`${cell.renderValue() ?? '-'}`}</Badge>
                        ) : meta?.type === 'date' ? (
                          formatDate(`${cell.renderValue()}`)
                        ) : meta?.type === 'tel' ? (
                          formatPhoneNumber(`${cell.renderValue()}`, true)
                        ) : meta?.type === 'switch' &&
                          `${cell.renderValue()}` === 'true' ? (
                          <CirlceIcon
                            as={allIcons.HiCheck}
                            bgColor='transparent'
                            m='auto'
                          />
                        ) : (
                          `${
                            isEmpty(cell.renderValue())
                              ? '-'
                              : cell.renderValue()
                          }`
                        )}
                        {cell.renderValue() != null && meta?.unity
                          ? ` ${meta?.unity}`
                          : ''}
                      </Td>
                    );
                  })}
                </Tr>
              )
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableCrm;
