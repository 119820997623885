export const customStyle = {
  container: (provided: any) => ({
    ...provided,
    width: '400px',
  }),
  option: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: '36px',
    overflow: 'auto',
  }),
};
