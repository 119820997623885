import { Flex } from '@chakra-ui/react';
import { useInstanceError } from './api/init';
import Login from './components/login/Login';
import { GlobalProvider, useGlobalContext } from './useGlobalContext';
import Navbar from './components/layout/Navbar';
import { TabsProvider } from './components/tabs/useTabsContext';
import Main from './components/layout/Main';
import { useEffect } from 'react';

type TResponse = { success: boolean; token: string; data: any };

const findElement = (el: Element, selector: string) => {
  if (el.parentElement?.querySelector(selector)) {
    (el.parentElement?.querySelector(selector) as HTMLButtonElement)?.click();
  } else if (el.parentElement) {
    findElement(el.parentElement, selector);
  }
};

const App = () => {
  useInstanceError();

  const { user, token, setToken, setLogin, setCustomMenuSelected } =
    useGlobalContext();

  const handleLogin = (resp: TResponse) => {
    if (!resp?.success) return;
    setToken(resp.token);
    setLogin('');
    setCustomMenuSelected('');
  };

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' && document.activeElement) {
        findElement(document.activeElement, '.submit');
      }
    });
  }, []);

  return (
    <TabsProvider>
      {!token || user.tempPasswordForced || user.tempPassword ? (
        <Flex
          backgroundImage='/bg.jpeg'
          backgroundSize='contains'
          bgColor='#000612'
          bgRepeat='no-repeat'
          height='100vh'
          alignItems='center'
          bgPosition='center'
        >
          <Login handleLogin={handleLogin} />
        </Flex>
      ) : (
        <Flex>
          <Navbar />
          <Main />
        </Flex>
      )}
    </TabsProvider>
    // <iframe
    //   width='600'
    //   height='600'
    //   // style='border:0'
    //   loading='lazy'
    //   // allowfullscreen
    //   src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=46.33993640000002,-0.0806095&zoom=18&maptype=satellite`}
    // ></iframe>
  );
};

export default (props: any) => (
  <GlobalProvider>
    <App {...props} />
  </GlobalProvider>
);
